import React from "react"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"

import appStoreBadge from "../../images/app-store-badge.svg"

const SaveSenseDetail = ({ data }) => (
  <Layout pageHeaderText="SaveSense" pageHeaderImage="forest.png">
    <SEO title="SaveSense" />
    <h2
      style={{
        textAlign: "center",
        fontSize: "3em",
        marginTop: "1rem",
        marginBottom: "2rem",
      }}
    >
      SaveSense: Save, Log, Indulge!
    </h2>
    <p style={{ textAlign: "center", maxWidth: "960px", margin: "0 auto" }}>
      SaveSense is an app that I developed for the iOS platform to help me track
      the decisions I make about spending money. When you make a decision that
      saves you money (like packing lunch rather than eating out), you log the
      amount saved towards a purchase for yourself that you might not have
      otherwise made.
    </p>
    <a
      style={{display: "block", textAlign:"center", marginTop:"2rem", marginBottom:"3rem", fontWeight: "700", fontSize: "1.5rem" }}
      href="https://apps.apple.com/us/app/savesense-save-log-indulge/id1439972208"
      target="_blank"
      rel="noopener noreferrer"
    >
      SaveSense for iOS
    </a>
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr",
        gridTemplateRows: "auto",
        gridColumnGap: "4rem",
        marginBottom: "1rem",
        marginTop: "1rem",
      }}
    >
      <Img fluid={data.savesenseone.childImageSharp.fluid}/>
      <Img fluid={data.savesensetwo.childImageSharp.fluid}/>
      <Img fluid={data.savesensethree.childImageSharp.fluid}/>
    </div>
    <Link style={{display:"block", textAlign:"right"}}to="/apps/savesense/privacy-policy">Privacy Policy</Link>
  </Layout>
)

export default SaveSenseDetail

export const query = graphql`
  query {
    savesenseone: file(relativePath: { eq: "savesense-0.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    savesensetwo: file(relativePath: { eq: "savesense-1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    savesensethree: file(relativePath: { eq: "savesense-2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
